import { render, staticRenderFns } from "./goal-card-pin.vue?vue&type=template&id=660367df&"
import script from "./goal-card-pin.vue?vue&type=script&lang=js&"
export * from "./goal-card-pin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-staging-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('660367df')) {
      api.createRecord('660367df', component.options)
    } else {
      api.reload('660367df', component.options)
    }
    module.hot.accept("./goal-card-pin.vue?vue&type=template&id=660367df&", function () {
      api.rerender('660367df', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tools/goals/components/card-modal/goal-card-pin.vue"
export default component.exports